<!--
 * @Description: 工程师管理
 * @Author: ChenXueLin
 * @Date: 2021-09-09 15:24:57
 * @LastEditTime: 2023-03-23 19:06:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userId">
              <el-input
                v-model="searchForm.userId"
                placeholder="用户ID"
                title="用户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeName">
              <el-input
                v-model="searchForm.employeeName"
                placeholder="工程师名称"
                title="工程师名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneNo">
              <el-input
                v-model="searchForm.phoneNo"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleId">
              <e6-vr-select
                v-model="searchForm.roleId"
                :data="userRoleList"
                placeholder="用户角色"
                title="用户角色"
                clearable
                :props="{
                  id: 'id',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userStatus">
              <e6-vr-select
                v-model="searchForm.userStatus"
                :data="userStatus"
                placeholder="用户状态"
                title="用户状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="frameWorkName">
              <el-input
                v-model="searchForm.frameWorkName"
                placeholder="所属部门"
                title="所属部门"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaName">
              <el-input
                v-model="searchForm.areaName"
                placeholder="所属区域"
                title="所属区域"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeNo">
              <el-input
                v-model="searchForm.employeeNo"
                placeholder="工号"
                title="工号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByUserName">
              <el-input
                v-model="searchForm.createByUserName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerAttributeList">
              <e6-vr-select
                v-model="searchForm.engineerAttributeList"
                :data="engineerAttributeData"
                placeholder="工程师属性"
                title="工程师属性"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="facilitatorList">
              <e6-vr-select
                v-model="searchForm.facilitatorList"
                placeholder="归属服务商"
                title="归属服务商"
                multiple
                clearable
                :data="facilitatorData"
                :props="{
                  id: 'serviceNo',
                  label: 'serviceProvider'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerSkillList">
              <e6-vr-select
                v-model="searchForm.engineerSkillList"
                :data="engineerSkillData"
                placeholder="工程师技能认证"
                title="工程师技能认证"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warehouseId">
              <e6-vr-select
                v-model="searchForm.warehouseId"
                placeholder="绑定实体仓"
                title="绑定实体仓"
                clearable
                :data="cityList"
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="exportData"
            v-has="105"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="135"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 地址维护弹框 -->
    <el-dialog
      v-dialogDrag
      title="信息维护"
      :visible="visibleDialog"
      width="900px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      v-loading="dialogLoading"
      custom-class="custom-dialog"
    >
      <el-form
        label-width="100px"
        :model="submitForm"
        :inline="true"
        :rules="submitFormRules"
        ref="form"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人">
              <el-input
                placeholder="联系人"
                v-model="clickRow.employeeName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话">
              <el-input
                placeholder="电话"
                v-model="clickRow.phoneNo"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工程师属性" prop="engineerAttribute">
              <e6-vr-select
                v-model="submitForm.engineerAttribute"
                :data="engineerAttributeData"
                placeholder="工程师属性"
                title="工程师属性"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工程师技能认证" prop="engineerSkill">
              <e6-vr-select
                v-model="submitForm.engineerSkill"
                :data="engineerSkillData"
                placeholder="工程师技能认证"
                title="工程师技能认证"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        label-width="100px"
        ref="ruleFormRef"
        :model="submitForm"
        :inline="true"
      >
        <el-row
          v-for="(item, index) in submitForm.engineerFacilitatorList"
          :key="index"
        >
          <el-col :span="10">
            <el-form-item
              label="归属服务商"
              :rules="{
                required: true,
                message: '请选择归属服务商',
                trigger: ['blur', 'change']
              }"
              :prop="'engineerFacilitatorList.' + index + '.facilitatorId'"
            >
              <e6-vr-select
                v-model="item.facilitatorId"
                placeholder="归属服务商"
                title="归属服务商"
                clearable
                :data="facilitatorList"
                :props="{
                  id: 'serviceNo',
                  label: 'serviceProvider'
                }"
                @change="
                  (val, node) => {
                    changeFacilitator(val, node, index);
                  }
                "
                style="width:200px;"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item
              label="生效周期"
              :rules="{
                required: true,
                message: '请选择生效周期',
                validator: (rules, value, cb) => {
                  checkTime(rules, value, cb, item.time);
                },
                trigger: ['blur', 'change']
              }"
              :prop="'engineerFacilitatorList.' + index + '.time'"
            >
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="生效周期"
                ref="DatePicker"
                :speedy="false"
                v-model="item.time"
                title="生效周期"
                :picker-options="pickerOption"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="生效周期（始）"
                end-placeholder="生效周期（止）"
                style="width:300px;"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item label="" class="icon">
              <i
                class="e6-icon-add_line1"
                title="新增"
                @click="handleAdd"
                v-if="index == 0"
              ></i>
              <i
                class="e6-icon-delete_line1"
                title="删除"
                v-if="index > 0"
                @click="deleteColumn(index)"
              ></i>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- </div> -->
      </el-form>
      <el-form
        label-width="100px"
        ref="submitForm"
        :model="submitForm"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="绑定实体仓" prop="areaIdList">
          <e6-vr-select
            v-model="submitForm.areaIdList"
            placeholder="绑定实体仓"
            title="绑定实体仓"
            clearable
            multiple
            :data="cityList"
            :props="{
              id: 'areaId',
              label: 'areaName'
            }"
            style="width:200px;"
          ></e6-vr-select>
        </el-form-item>
        <!-- </el-col> -->
      </el-form>
      <el-table border :data="engineerInfoVOS" highlight-current-row>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in engineerColumnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <e6-vr-select
              v-if="column.fieldName === 'address'"
              style="width:300px"
              v-model="row.address"
              :data="row.addressList"
              placeholder="省市区街道"
              title="省市区街道"
              virtual
              clearable
              :is-title="true"
              remote
              @filterChange="
                val => {
                  handleLoadAddressFilter(val, row);
                }
              "
              @change="
                (select, node) => {
                  handleCitySelect(select, node, row);
                }
              "
              :props="{
                id: 'fullName',
                label: 'fullName'
              }"
            ></e6-vr-select>
            <el-input
              placeholder="详细地址"
              v-model="row.insAddress"
              v-else-if="column.fieldName === 'insAddress'"
            ></el-input>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :businessId="clickRow.employeeId"
      logType="5"
    ></log-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { exportData } from "@/utils/download";
import {
  getEngineerList,
  exportEngineerList,
  findDownList,
  getUserRoleList,
  getAddressData,
  getEngineerDetail,
  saveEngineerDetail,
  getListAreaAll,
  getServiceProviderListPage
} from "@/api";
import { printError } from "@/utils/util";
import LogDialog from "@/components/logDialog.vue";
export default {
  name: "engineerManage",
  components: { LogDialog },
  data() {
    const areaIdListValidator = (rule, value, cb) => {
      if (value.length > 2) {
        cb(new Error("最多只能选择2个"));
      } else {
        cb();
      }
    };
    //自定义校验规则
    var checkTime = (rule, value, cb, time) => {
      let notEmpty = time.filter(ele => ele);
      if (notEmpty.length != 2) {
        cb(new Error("请选择生效周期"));
      } else {
        cb();
      }
    };

    return {
      checkTime,
      rules: {
        areaIdList: { validator: areaIdListValidator }
      },
      queryListAPI: getEngineerList,
      total: 0,
      roleId: [1], //工程师id
      userRoleList: [], //角色下拉框
      userStatus: [
        {
          id: 1,
          label: "启用"
        },
        {
          id: 0,
          label: "停用"
        }
      ],

      searchForm: {
        areaName: "", //所属区域名字
        createByUserName: "", //创建人
        employeeName: "", //工程师名字
        frameWorkName: "", //部门名字
        phoneNo: "", //电话
        warehouseId: "",
        roleId: "",
        createTime: [],
        startCreatedTime: "",
        endCreatedTime: "",
        userId: "",
        userStatus: "",
        facilitatorList: [],
        engineerSkillList: [],
        engineerAttributeList: [],
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户id",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "userCode",
          display: true,
          fieldLabel: "工号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeName",
          display: true,
          fieldLabel: "工程师名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerAttributeValue",
          display: true,
          fieldLabel: "工程师属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerSkillValue",
          display: true,
          fieldLabel: "工程师技能认证",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceStartTimeStr",
          display: true,
          fieldLabel: "服务生效日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceEndTimeStr",
          display: true,
          fieldLabel: "服务截至日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "facilitatorName",
          display: true,
          fieldLabel: "服务商名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNo",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleNameList",
          display: true,
          fieldLabel: "用户角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userStatusName",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "frameWorkName",
          display: true,
          fieldLabel: "所属部门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaNameList",
          display: true,
          fieldLabel: "所属地区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "warehouseListStr",
          display: true,
          fieldLabel: "绑定实体仓",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isExamineValue",
          display: true,
          fieldLabel: "是否考核",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noTaskCount",
          display: true,
          fieldLabel: "未完成调度信息",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowMonthTaskCount",
          display: true,
          fieldLabel: "本月完成调度信息",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByUserName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      // 信息维护
      clickRow: {},
      submitForm: {
        areaIdList: [],
        engineerAttribute: "",
        engineerSkill: "",
        engineerRelationId: "",
        engineerFacilitatorList: [
          {
            facilitatorId: "",
            isExamine: "",
            serviceStartTime: "",
            serviceEndTime: "",
            time: []
          }
        ]
      },
      submitFormRules: {
        engineerAttribute: [
          {
            required: true,
            message: "请选择工程师属性",
            trigger: ["blur", "change"]
          }
        ]
      },
      visibleDialog: false,
      dialogLoading: false,
      addressList: [],
      engineerInfoVOS: [],
      facilitatorData: [],
      facilitatorList: [],
      cityList: [],
      engineerColumnData: [
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "片区名称",
          width: 120,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "省市区街道",
          width: 200,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "insAddress",
          display: true,
          fieldLabel: "详细地址",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        }
      ],
      engineerAttributeData: [],
      engineerSkillData: [],
      isExamineList: [],
      pickerOption: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      logVisible: false
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          getUserRoleList(this.roleId),
          findDownList(["engineerAttribute", "engineerSkill", "isExamine"]),
          getServiceProviderListPage({ page: 1, pageSize: 999999 }),
          getListAreaAll({ areaType: 2 })
        ];
        let [roleRes, enumRes, facilitatorRes, cityRes] = await Promise.all(
          promiseList
        );
        //角色下拉框
        this.userRoleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
        //工程师属性
        this.engineerAttributeData = this.getFreezeResponse(enumRes, {
          path: "data.engineerAttribute"
        });
        //工程师技能
        this.engineerSkillData = this.getFreezeResponse(enumRes, {
          path: "data.engineerSkill"
        });
        this.isExamineList = this.getFreezeResponse(enumRes, {
          path: "data.isExamine"
        });
        //服务商
        this.facilitatorData = this.getFreezeResponse(facilitatorRes, {
          path: "data.array"
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },

    //格式化tableData
    formatList(data) {
      data.map(item => {
        item.areaNameList = item.areaNameList.join(",");
        item.roleNameList = item.roleNameList.join(",");
        item.warehouseListStr = item.warehouseList.join(",");
        if (item.engineerFacilitatorList.length) {
          item.children = item.engineerFacilitatorList.filter(
            (item, idx) => idx != 0
          );
          item.serviceStartTimeStr =
            item.engineerFacilitatorList[0].serviceStartTimeStr;
          item.serviceEndTimeStr =
            item.engineerFacilitatorList[0].serviceEndTimeStr;
          item.facilitatorName =
            item.engineerFacilitatorList[0].facilitatorName;
          item.isExamine = item.engineerFacilitatorList[0].isExamine;
          item.relationId = item.engineerFacilitatorList[0].relationId;
          item.serviceStartTime =
            item.engineerFacilitatorList[0].serviceStartTime;
          item.serviceEndTime = item.engineerFacilitatorList[0].serviceEndTime;
          item.facilitatorId = item.engineerFacilitatorList[0].facilitatorId;
          item.isExamineValue = item.engineerFacilitatorList[0].isExamineValue;
        } else {
          item.children = [];
        }
      });
      return data;
    },
    changeFacilitator(val, node, index) {
      let engineerFacilitatorList = this.submitForm.engineerFacilitatorList;
      engineerFacilitatorList[index].isExamine = node.isExamine.toString();
    },
    //新增
    handleAdd() {
      let engineerFacilitatorList = this.submitForm.engineerFacilitatorList;
      engineerFacilitatorList.push({
        facilitatorId: "",
        isExamine: "",
        serviceStartTime: "",
        serviceEndTime: "",
        time: []
      });
    },
    //删除单行
    deleteColumn(index) {
      let engineerFacilitatorList = _.cloneDeep(
        this.submitForm.engineerFacilitatorList
      );
      engineerFacilitatorList.splice(index, 1);
      this.submitForm.engineerFacilitatorList = engineerFacilitatorList;
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates;
      if (row.employeeId) {
        operates = [
          {
            label: "详情",
            id: 1
          },
          {
            label: "信息维护",
            id: 2
          },
          {
            label: "操作日志",
            id: 3
          }
        ];
      }

      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "operationsCenter/engineerDetail",
          params: {
            refresh: true,
            userId: row.userId
          }
        });
      }
      if (val.id == 2) {
        this.visibleDialog = true;
        this.clickRow = row;

        this.submitForm.engineerAttribute = row.engineerAttribute
          ? row.engineerAttribute.toString()
          : "";
        this.submitForm.engineerSkill = row.engineerSkill
          ? row.engineerSkill.toString()
          : "";

        this.submitForm.engineerRelationId = row.engineerRelationId;
        this.submitForm.engineerFacilitatorList = row.engineerFacilitatorList
          .length
          ? row.engineerFacilitatorList
          : [
              {
                facilitatorId: "",
                isExamine: "",
                serviceStartTime: "",
                serviceEndTime: "",
                time: []
              }
            ];
        this.submitForm.engineerFacilitatorList.map(item => {
          item.isExamine = item.isExamineValue ? item.isExamine.toString() : "";
          item.time = [
            item.serviceStartTimeTim ? item.serviceStartTimeTim : "",
            item.serviceEndTimeTim ? item.serviceEndTimeTim : ""
          ];
        });
        this.getServiceProviderListPage();
        this.getEngineerDetail(row.employeeId);
      }
      if (val.id == 3) {
        this.clickRow = row;
        this.logVisible = true;
      }
    },
    //查询服务商
    async getServiceProviderListPage() {
      try {
        this.dialogLoading = true;
        let res = await getServiceProviderListPage({
          page: 1,
          pageSize: 999999
        });
        this.facilitatorList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    async getEngineerDetail(employeeId) {
      try {
        this.dialogLoading = true;
        let res = await getEngineerDetail({ employeeId });
        let engineerInfoVOS = res.data.engineerInfoVOS;
        engineerInfoVOS.map(item => {
          item.addressList = [];
          item.address = item.address ? item.address : "";
          if (item.address) {
            this.loadAddressData(item.address, item);
          }
        });
        this.engineerInfoVOS = engineerInfoVOS;
        this.submitForm.areaIdList = res.data.areaIdList;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleLoadAddressFilter: _.debounce(async function(val, row) {
      if (val) {
        this.submitForm.defaultCity = "";
        this.loadAddressData(val, row);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "", row) {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        row.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 处理城市选择
    handleCitySelect(select, node, row) {
      row.provinceName = node.provinceName;
      row.province = node.provinceCode;
      row.cityName = node.cityName;
      row.city = node.cityCode;
      row.insCountyName = node.countyName;
      row.insCounty = node.countyCode;
      row.streetName = node.streetName;
      row.street = node.streetCode;
      row.address = node.fullName;
    },
    //  导出
    exportData() {
      exportData(this, exportEngineerList);
    },
    handleSubmit() {
      const p1 = new Promise(resolve => {
        this.$refs["form"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p2 = new Promise(resolve => {
        this.$refs["submitForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p3 = new Promise(resolve => {
        this.$refs["ruleFormRef"].validate(valid => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2, p3]).then(() => {
        this.saveEngineerDetail();
      });
      // console.log(this.$refs.ruleFormRef);
      // this.$refs.ruleFormRef.validate(valid => {
      //   if (valid) {
      //     console.log(valid);
      //   }
      // });
      // this.$refs.form.validate(valid => {
      //   if (valid) {
      //     this.$refs.submitForm.validate(valid => {
      //       if (valid) {
      //         this.$refs.ruleFormRef.validate(valid => {
      //           if (valid) {
      //             this.saveEngineerDetail();
      //           }
      //         });
      //       }
      //     });
      //   }
      // });
    },
    //保存工程师信息
    async saveEngineerDetail() {
      try {
        this.dialogLoading = true;
        let engineerInfoVOS = _.cloneDeep(this.engineerInfoVOS);
        engineerInfoVOS.map(item => {
          delete item.addressList;
        });
        let engineerFacilitatorList = _.cloneDeep(
          this.submitForm.engineerFacilitatorList
        );
        engineerFacilitatorList.map(item => {
          item.serviceEndTime = item.time[1];
          item.serviceStartTime = item.time[0];
        });
        let res = await saveEngineerDetail({
          ...this.submitForm,
          engineerFacilitatorList: engineerFacilitatorList.filter(
            item =>
              item.facilitatorId && item.serviceStartTime && item.serviceEndTime
          ),
          engineerInfoVOS,
          employeeId: this.clickRow.employeeId
        });
        if (res.code == "OK") {
          this.$message.success("保存成功");
          this.handleClose();
          this.handleSearch();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.visibleDialog = false;
      this.submitForm = {
        engineerAttribute: "",
        engineerSkill: "",
        engineerRelationId: "",
        areaIdList: [],
        engineerFacilitatorList: [
          {
            facilitatorId: "",
            isExamine: "",
            serviceStartTime: "",
            serviceEndTime: "",
            time: []
          }
        ]
      };
      this.$refs.submitForm.resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;
    max-height: 500px;
    overflow-y: auto;
    .el-form {
      .el-input {
        width: 200px;
      }
    }
    .icon {
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
  }
}
</style>
