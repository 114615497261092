<!--
 * @Description: 操作日志弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-20 15:22:04
 * @LastEditTime: 2022-07-01 09:21:49
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 异常完成弹框 -->
  <el-dialog
    v-dialogDrag
    title="操作日志"
    :visible="visible"
    width="800px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    v-loading="dialogLoading"
    @open="openDialog"
    custom-class="createDispatchDialog"
  >
    <el-table border :data="tableData" highlight-current-row :height="500">
      <el-table-column
        show-overflow-tooltip
        align="center"
        label="序号"
        min-width="80"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.$index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        align="center"
        prop="updatedTime"
        label="操作时间"
        min-width="160"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="operationType"
        align="center"
        label="操作类型"
        min-width="140"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="msg"
        align="center"
        label="详情"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="userName"
        align="center"
        label="操作人"
        min-width="140"
      >
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { printError } from "@/utils/util";
import { getServiceLog } from "@/api";
export default {
  name: "logDialog",
  components: {},
  data() {
    return {
      dialogLoading: false,
      loadingBackground: "rgba(255,255,255,0.5)", // loading 遮罩颜色
      tableData: []
    };
  },
  props: {
    logVisible: {
      type: Boolean,
      default: false
    },
    businessId: {
      type: [Number, String],
      default: ""
    },
    logType: {
      type: [Number, String],
      default: ""
    },
    isQueryLogDetail: {
      type: Boolean,
      default: false
    }
  },
  mixins: [],
  computed: {
    visible: {
      get() {
        return this.logVisible;
      },
      set(val) {
        this.$emit("update:logVisible", val);
      }
    }
  },
  watch: {},
  methods: {
    openDialog() {
      this.getLogList();
    },
    async getLogList() {
      try {
        this.dialogLoading = true;
        let res = await getServiceLog({
          businessId: this.businessId,
          logType: this.logType,
          isQueryLogDetail: this.isQueryLogDetail
        });
        if (res.code == "OK") {
          this.tableData = res.data;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.$emit("update:logVisible", false);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-input {
    width: 300px;
  }
  .til {
    font-size: 12px;
    color: #606266;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
}
</style>
